
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;900&display=swap');

:root {
  --brand-color: #a05392;
  --brand-color-500: #992b85;
  
}

body {
  font-family: 'Nunito', sans-serif;
}

.ant-btn-primary {
  background: var(--brand-color-500);

  border-color: var(--brand-color-500);
}

.ant-btn-primary:hover {
  background: var(--brand-color-500);

  border-color: var(--brand-color-500);
}

.ant-table-filter-trigger.active {
  color: var(--brand-color-500);
}

.ant-btn-sm:hover {
  color: var(--brand-color-500);
  border-color: var(--brand-color-500);
}

.ant-layout-sider {
  background: var(--brand-color-500);
}

.ant-layout-sider-zero-width-trigger{
  background: var(--brand-color-500);
}

.login-layout {
  background: var(--brand-color-500);
}