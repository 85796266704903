@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;900&display=swap);
.login-layout {
 
  background-size: "cover";
}

.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}


:root {
  --brand-color: #a05392;
  --brand-color-500: #992b85;
  
}

body {
  font-family: 'Nunito', sans-serif;
}

.ant-btn-primary {
  background: #992b85;
  background: var(--brand-color-500);

  border-color: #992b85;

  border-color: var(--brand-color-500);
}

.ant-btn-primary:hover {
  background: #992b85;
  background: var(--brand-color-500);

  border-color: #992b85;

  border-color: var(--brand-color-500);
}

.ant-table-filter-trigger.active {
  color: #992b85;
  color: var(--brand-color-500);
}

.ant-btn-sm:hover {
  color: #992b85;
  color: var(--brand-color-500);
  border-color: #992b85;
  border-color: var(--brand-color-500);
}

.ant-layout-sider {
  background: #992b85;
  background: var(--brand-color-500);
}

.ant-layout-sider-zero-width-trigger{
  background: #992b85;
  background: var(--brand-color-500);
}

.login-layout {
  background: #992b85;
  background: var(--brand-color-500);
}
